<template>
	<div class="page">
		<navbar />
		<breadcrumb :list="breadcrumbList" />
		<div class="main flex-direction-column">
			<div class="header">
				<span>Result:</span>
				<span>{{ tableData.length }}</span>
			</div>
			<div class="table" v-if="isShow">
				<el-table ref="table" tooltip-effect="dark" :data="tableData" :header-cell-style="{ background: '#F7F7F7', color: '#000' }" style="width: 100%">
					<el-table-column align="center" type="index" label="#" width="50" key="index"> </el-table-column>
					<el-table-column align="center" prop="" width="180" label="BRAND NAME/MODEL NAME" key="BRAND NAMEMODEL NAME" :render-header="renderheader">
						<template slot-scope="scope">
							<div class="flex-direction-column">
								<span>{{ scope.row.brand || "-" }}</span>
								<span>{{ scope.row.model || "-" }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="" width="180" label="GROUP NAME/SUBGROUP NAME" key="GROUP NAME SUBGROUP" :render-header="renderheader">
						<template slot-scope="scope">
							<div class="flex-direction-column">
								<span>{{ scope.row.cate_main_name || "-" }}</span>
								<span>{{ scope.row.cate_min_name || "-" }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column align="center" width="80" prop="pos" label="PNC" key="PNC"> </el-table-column>
					<el-table-column align="center" width="80" prop="start_time" label="SOP" key="SOP"> </el-table-column>
					<el-table-column align="center" width="80" prop="end_time" label="EOP" key="EOP"> </el-table-column>
					<el-table-column align="center" prop="" width="180" label="OEM CODE/SUBSTITUTION CODE" key="OEM CODE SUBSTITUTION CODE" :render-header="renderheader">
						<template slot-scope="scope">
							<div class="flex-direction-column">
								<span>{{ scope.row.oe || "-" }}</span>
								<span>{{ scope.row.replace_oe || "-" }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="" label="OEM NAME/REMARK" key="OEM NAME REMARK" :render-header="renderheader">
						<template slot-scope="scope">
							<div class="flex-direction-column">
								<span>{{ scope.row.oe_name || "-" }}</span>
								<span>
									{{ scope.row.remark || "-" }}
								</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column width="50" align="center" prop="" label="QTY" key="num">
						<template slot-scope="scope">
							<div class="flex-direction-column">
								<span>{{ scope.row.num || "-" }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="" label="MSRP" key="MSRP" width="80">
						<template slot-scope="scope">
							{{ scope.row.price || "-" }}
						</template>
					</el-table-column>
					<el-table-column align="center" prop="" label="OES CODE" key="OES CODE" width="100">
						<template slot-scope="scope">
							{{ "-" }}
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
import { commonMixins } from "@/mixins/common.js";
import { getReplaceOe, getOePrice } from "@/service/api/index.js";
export default {
	mixins: [commonMixins],
	data() {
		return {
			tableData: [],
			breadcrumbList: [],
			isShow: false,
			vehicleInfo: null,
		};
	},

	mounted() {
		this.tableData = JSON.parse(localStorage.getItem("subgroups"));
		const vehicleInfo = JSON.parse(sessionStorage.getItem("vehicleList"));

		this.query = this.$route.query;
		this.vehicleInfo = vehicleInfo;

		if (this.query.standardnames) {
			this.breadcrumbList = [
				{
					name: this.query.chassis_no || this.query.bread || this.query.val,
					path: "/vehicleResult",
				},
				{
					name: this.query.standardnames,
					path: "/searchResult",
				},
				{
					name: "Main Group",
					path: "/mainGroup",
				},
				{
					name: this.query.cate_min_name,
					path: "/mainGroupEngine",
				},
				{
					name: "Subgroups",
				},
			];
		} else {
			this.breadcrumbList = [
				{
					name: this.query.chassis_no || this.query.bread || this.query.val,
					path: "/vehicleResult",
				},
				{
					name: "Main Group",
					path: "/mainGroup",
				},
				{
					name: this.query.cate_min_name,
					path: "/mainGroupEngine",
				},
				{
					name: "Subgroups",
				},
			];
		}

		this.initTableData();
	},

	methods: {
		renderheader(h, { column, $index }) {
			return h("span", {}, [h("span", {}, column.label.split("/")[0]), h("br"), h("span", {}, column.label.split("/")[1])]);
		},
		async initTableData() {
			for (let index = 0; index < this.tableData.length; index++) {
				const element = this.tableData[index];
				await this.getReplaceOeApi(element.oe, index);
				await this.getOePriceApi(element.oe, index);
				if (index === this.tableData.length - 1) {
					this.isShow = true;
				}
			}
		},
		async getReplaceOeApi(oe, index) {
			const { datas } = await getReplaceOe({ oe });
			if (datas) this.tableData[index].replace_oe = datas.items[0].replace_oe;
			const { Brand, Models, brand_name, car_model, SOP, EOP } = this.vehicleInfo;
			this.tableData[index].brand = Brand || brand_name;
			this.tableData[index].model = Models || car_model;
			this.tableData[index].cate_main_name = this.query.cate_main_name;
			this.tableData[index].cate_min_name = this.query.cate_min_name;
			this.tableData[index].sop = SOP;
			this.tableData[index].eop = EOP;
		},
		async getOePriceApi(oe, index) {
			const { datas } = await getOePrice({ oe });
			if (datas) this.tableData[index].price = datas.price;
		},
	},
};
</script>

<style scoped lang="less">
.page {
	background-color: #f8f9fb !important;
	padding-bottom: 30px;
	min-height: calc(100vh - 30px);
}
.bread {
	background-color: #fff;
}
.bread-box {
	width: 1400px;
	margin: 0 auto;
	height: 60px;
	display: flex;
	align-items: center;
	.el-icon-location {
		margin-right: 6px;
		font-size: 16px;
	}
	.breadName {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #fd7e14;
		cursor: pointer;
	}
	.separator-icon {
		display: inline-block;
		margin-left: 6px;
		margin-right: 6px;
	}
}
.main {
	width: 1400px;
	margin: 15px auto;
	background-color: #fff;
	.header {
		padding: 15px 25px;
		border-bottom: 1px solid #fd7e14;
		span {
			font-size: 16px;
			color: #000000;
		}
	}
}
.table {
	min-height: calc(100vh - 250px);
}
</style>
